var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"website-client@43365"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/account";

import * as Sentry from '@sentry/nextjs'
import { BrowserTracing } from '@sentry/nextjs'

import { isSupportedBrowser } from '@scentregroup/shared/helpers/supported-browsers'

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Navigator {
    userAgentData?: unknown
  }
}

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENV = process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV

function sendIfSupportedBrowser<
  TToSend extends Sentry.Event | Sentry.Breadcrumb,
>(toSend: TToSend, request: Sentry.Request | undefined): TToSend | null {
  // Detect user agent client hints - only very new browsers have this
  const { userAgentData } = navigator
  if (typeof userAgentData === 'object' && userAgentData !== null) {
    return toSend
  }

  return isSupportedBrowser(request?.headers?.['User-Agent']) ? toSend : null
}

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  integrations: [
    new BrowserTracing({
      tracingOrigins: [
        'localhost',
        /^https:\/\/api\.([^.]+\.)*scentregroup\.io\//,
        /^\//,
      ],
    }),
  ],

  tracesSampleRate: 1,

  beforeSend: (event: Sentry.Event) => {
    return sendIfSupportedBrowser(event, event?.request)
  },
  beforeBreadcrumb: (
    breadcrumb: Sentry.Breadcrumb,
    hint?: Sentry.BreadcrumbHint | undefined
  ) => {
    return sendIfSupportedBrowser(breadcrumb, hint?.event?.request)
  },
})

Sentry.setTag('subapp', 'account')
